#service-selection-widget {
    display: flex;
    align-items: center;

    padding: 0 0.5rem;
    background: #f8f8f8;
    margin-bottom: 1rem;
    border-radius: 4px;

    .field-selector {
        flex: 1;

        select:not(#hours) {
            min-height: 280px;

            // to wrap overflowing optgroup and option texts
            white-space: normal;
            overflow: auto;
        }

        &:first-child {
            margin-right: 1rem;
        }

        &:last-child {
            margin-left: 1rem;
        }

        &#field-selector-left {
            max-width: 300px;

            .table {
                td {
                    padding: 0 0.75rem;
                }
            }
        }

        &#field-selector-right {
            .table {
                tbody {
                    min-height: 280px;
                    white-space: normal;
                    overflow: auto;
                    
                    padding: 0.47rem 0.75rem;
                    font-size: 0.8125rem;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    appearance: none;
                    border-radius: 0.25rem;
                }

                td{
                    min-width: 100px;
                }
            }
        }
    }

    .action-btn {
        div {
            &:first-child {
                margin-bottom: 0.5rem;
            }

            button {
                padding: 0.5rem;
            }
        }
    }
}
