.overlay-loader-wrapper {
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.16);
  z-index: 9999;
}
