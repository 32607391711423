.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.height-adusted-card {
  height: 100%;
}

.membership-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 20px 0;
  .membership-card-wrapper{
    background-color: #f0c8ed;
    width: 550px;
    height: 310px;
    display: flex;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    background-image: url(../images/happy60plus/happy60plus_icon.png);
    background-size: auto 120%;
    background-repeat: no-repeat;
    background-position: 111px -13px;
    background-blend-mode: overlay;
  }
  
  .membership-card-wrapper .left{
    align-items: center;
    width: 35%;
    // background: linear-gradient(to right,#01a9ac,#01dbdf);
    padding: 30px 0px 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    color: #000;
    z-index: 9;
  }
  
  .membership-card-wrapper .left img{
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .membership-card-wrapper .left h5{
    margin-bottom: 10px;
    color: #000;
  }
  
  .membership-card-wrapper .left h3{
    font-size: 1.4rem;
    color: #000;
  }
  
  .membership-card-wrapper .right{
    width: 65%;
    // background: #000;
    padding: 30px 25px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 9;
  }
  
  .membership-card-wrapper .right .info h2,
  .membership-card-wrapper .right .projects h3{
      margin-bottom: 1rem;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid #e0e0e0;
      color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.4rem;
  }
  
  .membership-card-wrapper .right .info_data,
  .membership-card-wrapper .right .projects_data{
    display: flex;
    justify-content: space-between;
  }
  
  .membership-card-wrapper .right .info_data .data h5,
  .membership-card-wrapper .right .projects_data .data h5{
    color: #000;
    font-weight: unset;
      margin-bottom: 5px;
      font-size: 17px;
  }
  
  .membership-card-wrapper .right .info_data .data h3,
  .membership-card-wrapper .right .projects_data .data p{
    font-size: 1.4rem;
    margin-bottom: 1rem;
    word-break: break-all;
    color: #000;
  }
  
  .membership-card-wrapper .social_media ul{
    display: flex;
  }
  
  .membership-card-wrapper .social_media ul li{
    width: 45px;
    height: 45px;
    background: linear-gradient(to right,#01a9ac,#01dbdf);
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;
    line-height: 45px;
  }
  
  .membership-card-wrapper .social_media ul li a{
    color :#000;
    display: block;
    font-size: 18px;
  }
  .membership-id {
    font-size: 20px;
    color: #000;
  }  
}

.custom-radio {
  display: flex;
  align-items: center;
  input {
    margin-right: 5px;
  }
  label {
    margin: unset;
  }
}

.healthBuddy {
  .card-title, .card-text {
    text-transform: capitalize;
  }  
}
.healthBuddy:hover{
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.selected-healthBuddy {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  background: #BBDEFB;
}

.service-modal {
  min-width: 840px;
}

@media only screen and (max-width: 860px) {
  .service-modal {
    min-width: unset;
  }
}

.mobileNumberL {
  font-size: 1.5rem;
}
/* Enquiry*/
#YogaModal{
  .modal-content {
    background: unset;
    border: unset;
  }
  .modal-header {
    border: unset;
  }
}
.triangle {
  background-image: url(../images/happy60plus/enquiry.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
}
.enquiry-button {
  padding: 15px;
  font-size: 1.2rem;
}
.btn-gradient {
  color: #FFFFFF;
  background: linear-gradient(90deg,#ee0979 0,#ff6a00 100%);
  box-shadow: unset !important;
  transform: unset !important;

  &::after {
      display: none;
  }
  &::before {
      opacity: 0;
      visibility: hidden;
      width: 100%;
      background: linear-gradient(135deg,#23bdb8 0,#43e794 100%);
      left: 0;
      border-radius: 4px;
  }
  &:hover, &:focus, &:active {
      color: #FFFFFF;
      background: linear-gradient(90deg,#ee0979 0,#ff6a00 100%);
      box-shadow: unset !important;

      &::before {
          opacity: 1;
          visibility: visible;
          width: 100%;
      }
  }
  &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
      color: #FFFFFF;
      background: linear-gradient(90deg,#ee0979 0,#ff6a00 100%);
      box-shadow: unset !important;

      &::before {
          opacity: 1;
          visibility: visible;
          width: 100%;
      }
  }
  &.disabled, &:disabled {
      color: #FFFFFF;
      background: linear-gradient(90deg,#ee0979 0,#ff6a00 100%);
      box-shadow: unset !important;

      &::before {
          opacity: 1;
          visibility: visible;
          width: 100%;
      }
  }
}
/* Enquiry*/

.gold-card {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  img, .triangle {
    width: 80% !important;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .gold-card {
    width: 75%;
    margin: 0 auto;
    text-align: center;
    img, .triangle {
      width: 100% !important;
      margin: 0 auto;
    }
  }
}

@media (max-width: 425px) {
  .gold-card {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img, .triangle {
      width: 100% !important;
      margin: 0 auto;
    }
  }
}

/*================================================
Testimonials CSS
=================================================*/
.triangle-yoga {
  background-image: url(../images/happy60plus/enquiry.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 600px;
  width: 100%;  
  padding: 0;
}
.single-testimonials-item {
  -webkit-box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 45px;
  background-color: #ffffff;
  position: relative;
  padding: 110px 65px 60px;
  border-radius: 5px;
  text-align: center;
}

.single-testimonials-item p {
  margin-bottom: 0;
  color: #4a6f8a;
  font-size: 15px;
}

.single-testimonials-item .client-info {
  text-align: left;
  position: relative;
  padding-left: 95px;
  margin-top: 25px;
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;
}

.single-testimonials-item .client-info img {
  border-radius: 5px;
  border: 2px solid #f4f5fe;
  width: 80px !important;
  height: 80px !important;
  padding: 5px;
  display: inline-block !important;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-testimonials-item .client-info .rating svg {
  color: #ff612f;
  width: 16px;
  margin-right: 3px;
}

.single-testimonials-item .client-info .rating svg:last-child {
  margin-right: 0;
}

.single-testimonials-item .client-info h3 {
  margin-bottom: 0;
  margin-top: 8px;
  font-size: 17px;
}

.single-testimonials-item .client-info span {
  display: block;
  color: #c679e3;
  margin-top: 5px;
  font-size: 13px;
}

.single-testimonials-item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 5px;
  bottom: -15px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
  z-index: -1;
  margin-left: 30px;
  margin-right: 30px;
}

.ml-feedback-slides .single-testimonials-item {
  margin-top: 40px;
}

.remove-before {
  padding: 60px 0px 25px !important;
  text-align: justify !important;
  padding-top: 0 !important;
  // margin-top: 100px !important;
  &::before {
      content:none !important;
  }
}
.remove-before>.owl-details {
  // max-height: 400px !important;
  // overflow-y: auto !important;
  padding: 10px;
}
.remove-before>.client-info {
  text-align: left;
  position: relative;
  padding-left: unset !important;
  margin-top: 25px;
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;
}
