/*================================================
Chatbot widget styles start
=================================================*/

.widget {
  display: none;
  width: calc(100% - 2rem);
  max-width: 400px;
  height: 80vh;
  max-height: 550px;
  bottom: 3rem;
  right: 3rem;
  position: fixed;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 5px 0 #00000096;
  z-index: 99;
}

.askelie-bot {
  background-color: rgb(255, 255, 255);
  padding: 0 10px;

  border-radius: 10px;

  position: relative;

  .chat-overlay-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 9999;

    .spinner-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 160px;
      }
    }
  }

  .chat-header {
    padding: 0.5rem 1rem;
    border-radius: 10px 10px 0px 0px;
    color: #ffffff;

    h6 {
      color: #ffffff;
    }

    .widget-menu {
      left: -140px !important;
    }
  }

  .chat-body {
    padding: 0 1rem;
    height: 432px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      overflow-y: hidden;
      width: 0px;
    }

    .botTyping>div {
      width: 10px;
      height: 10px;
      background-color: #060dcfc7;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      margin-right: 5px;
    }

    .botTyping .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .botTyping .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {

      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {

      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    .bot-response {
      display: flex;
      align-items: flex-start;
      margin: 1rem 0;

      .bot-profile-photo {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.5rem;
        border-radius: 50%;
      }

      .bot-typing-icon {
        -webkit-animation: pulse 1.4s infinite ease-in-out both;
        animation: pulse 1.4s infinite ease-in-out both;

        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }

      @keyframes pulse {
        0% {
          -webkit-transform: scale(0.25);
          transform: scale(0.25);
        }

        20% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
        }

        40% {
          -webkit-transform: scale(0.75);
          transform: scale(0.75);
        }

        50% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        60% {
          -webkit-transform: scale(0.75);
          transform: scale(0.75);
        }

        80% {
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
        }

        100% {
          -webkit-transform: scale(0.25);
          transform: scale(0.25);
        }
      }

      .bot-msg-div {
        min-width: 25%;
        max-width: 60%;

        .bot-msg {
          background: #e3e7ee;
          padding: 0.5rem 1rem;
          border-radius: 5px 20px 20px 20px;
          white-space: break-spaces;

          &.btns-div {
            margin-left: 4rem;
            border-radius: 10px;
            width: 240px;
            padding-bottom: 0;
            background: #ffffff;
            box-shadow: 0 0 5px 1px #dbdade;

            .bot-msg-btn {
              width: 100%;
              margin-bottom: 0.5rem;
              // background: #040474;
              // color: #ffffff;
              border-radius: 10px;

              padding: 0.5rem 1rem;
              display: inline-block;
              font-weight: 400;
              vertical-align: middle;
              user-select: none;
              // font-size: 1rem;
              line-height: 1;
              cursor: pointer;
              text-align: center;
            }

            .selected-filename {
              padding: 0 0.5rem;
              margin-bottom: 1rem !important;
              margin-top: -1rem !important;
            }
          }

          p {
            margin: 0;
            // word-break: break-all;
          }
        }

        .bot-msg-img {
          margin-left: 4rem;
        }
      }

      .bot-typing-div {
        .bot-msg {
          background: #e3e7ee;
          padding: 0.5rem 1rem;
          border-radius: 5px 20px 20px 20px;
          white-space: break-spaces;
        }
      }

      #pdf-response {
        min-width: 25%;
        max-width: 60%;
        border: 1px solid rgb(217, 217, 217);
        border-radius: 5px;

        .pdf-icon-div {
          padding: 1rem;
          border-right: 1px solid rgb(217, 217, 217);
        }

        .pdf-link-div {
          padding: 1rem;
          flex-grow: 1;
          background: white;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }

    .datetime-picker-response, .selection-response {
      min-width: 25%;
      max-width: 50%;
      width: auto;
      margin-left: 4rem !important;

      .date-input,
      .time-input,
      .datetime-input {
        border: 1px solid rgb(217, 217, 217) !important;
        border-radius: 5px !important;
        padding: 0.25rem 0.5rem !important;
      }
    }
  }

  .chat-footer {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    input {
      padding: 0.5em;
      width: 100%;
      border: 0;
      padding-left: 15px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #d8d6de;
    }

    .send-btn {
      margin: 1rem 0.5rem;
    }
  }

  .bot-story {
    padding: 1rem;

    h1 {
      color: #ffffff;
    }
  }

  .message-details {
    overflow-y: auto;
  }
}

.profile_div {
  /* display: none; */
  position: fixed;
  padding: 5px;
  bottom: 3rem;
  right: 3rem;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;

  z-index: 99;
}

.imgProfile {
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.16), 0 0px 10px 0 #00000096;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

@media (max-width: 600px) {
  .profile_div {
    bottom: 1rem;
    right: 1rem;
  }

  .widget {
    bottom: 1rem;
    right: 1rem;
  }
}

.user-response {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin: 1rem 0;

  .user-profile-photo {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
  }

  .user-msg-div {
    min-width: 25%;
    max-width: 60%;

    .user-msg {
      margin: 0;
      background: #040474;
      padding: 0.5rem 1rem;
      border-radius: 20px 5px 20px 20px;
      color: rgb(255, 255, 255);
      white-space: break-spaces;

      p {
        margin: 0;
        // word-break: break-all;
      }
    }
  }
}

/*================================================
Chatbot widget styles end
=================================================*/
